.footer
	padding: 20px
	&__container
		display: flex
		align-items: center
		justify-content: space-between
	&__logotype
		fill: $brand
		svg
			width: 128px
			height: 32px
	&__nav
		display: flex		
		margin: 25px 0px
	&__link
		font-size: 14px
		font-weight: bold
		color: $light-gray
		position: relative
		display: flex
		&:before
			content: ''
			width: 2px
			height: 100%
			margin-right: 10px
			display: none
			background-color: $light-gray
		& + ^[0]__link
			margin-left: 10px
			&:before
				display: block
		&:hover
			color: $brand		
	&__year
		color: $black
		font-size: 12px
	
	// <= 768px
	+media-table-s()
		display: none