// *
// 	box-sizing: border-box

body 
	display: flex
	flex-direction: column
// 	margin: 0px
// 	min-height: 100vh
// 	color: $black
// 	font-size: 16px
// 	line-height: 130%
	
a
	text-decoration: none
	transition: all $transition
	&:not([class])
		color: $blue
		&:hover
			text-decoration: underline

img
	transition: opacity $transition
	&[loading]	
	&[data-lazy-src]	
		opacity: 0
	&.loaded
		opacity: 1

.title
	font-weight: 800
	line-height: normal
	margin: 0px 0px 20px

.wrapper
	display: flex
	flex: 1 0 auto

.main
	width: 100%

.container
	width: 100%
	max-width: 1200px
	margin-left: auto
	margin-right: auto

.section	
	padding: 140px 20px


// --- Адаптивная часть ---


// <= 980px
+media-table-m()
	.section
		padding: 60px 20px