// commons
.home
	font-size: 18px
	line-height: normal
	overflow-x: hidden
	ul, ol
		padding: 0px
		list-style-type: none


// welcome
.welcome
	.title
		color: $brand
	.btn
		width: 100%
		max-width: 340px
		margin: 40px 0px 0px
	
	&__container
		display: flex
		align-items: center
	&__col
		flex-basis: 50%
		&.--pic
			width: 100%
			max-width: 560px
	
	// <= 768px
	+media-table-s()
		&__container
			flex-direction: column
		&__col
			flex-grow: 1
			flex-basis: 100%
			&.--pic
				order: -1
				margin: 0px 0px 20px


// about
.about
	color: #fff
	text-align: right
	background-color: #619fd9	
	&__row
		display: flex
		align-items: center
		margin: -10px
	&__col
		margin: 10px
		&.--pic
			display: block
			width: 100%
	&__list
		counter-reset: aboutCounter
		li
			padding-right: 45px
			position: relative
			&:after
				content: counter(aboutCounter)
				counter-increment: aboutCounter
				font-size: 18px
				width: 28px
				height: 28px
				border-radius: 100px
				border: 2px solid #fff

				top: -3px
				right: 0px
				position: absolute
				
				display flex
				align-items: center
				justify-content: center
			& + li
				margin: 50px 0px 0px
	
	// <= 768px
	+media-table-s()
		text-align: left
		&__row
			flex-direction: column
		&__list
			li
				padding: 0px 0px 0px 45px
				&:after
					left: 0px


// complex
.complex
	color: #fff
	position: relative
	margin: 0px 0px 100px
	background-color: $brand-dark	
	&:before
		content: ''
		
		right: -10px
		bottom: -100px
		left: -10px
		position: absolute
				
		height: 100px
		display: block
		background-color: $brand-dark
		border-radius: 0 0 50% 50% / 0 0 100% 100%;
	&__title
		text-align: center	
	&__list
		margin: 80px 0px 0px
		counter-reset: complexCounter
		li
			display: block
			position: relative
			padding-left: 60px
			&:before
				content: counter(complexCounter)
				counter-increment: complexCounter
				font-size: 36px
				font-weight: bold

				top: 0px
				left: 0px
				position: absolute
			& + li
				margin: 100px 0px 0px	
			.title
				display: block
	
	// <= 768px
	+media-table-s()
		&__list
			li
				& + li
					margin: 40px 0px 0px
				.title
					font-size: 18px
// /complex


// numbers
.numbers
	&__title
		color: $brand
	&__row
		display: flex
		flex-wrap: wrap
		margin: -25px
	&__col
		margin: 25px
		flex-basis: calc((100% / 3) - 50px)
		span
			color: $brand
			font-size: 48px
			font-weight: bold
			display: block
			margin: 0px 0px 5px
			
	// <= 768px
	+media-table-s()
		&__col
			flex-basis: calc((100% / 2) - 50px)
			span
				font-size: 24px


// cases
.cases
	&__title
		color: $brand
		text-align: center
		margin: 0px 0px 100px
	&__row
		display: flex
		align-items: center
		justify-content: space-between
		margin: -20px
		& + .cases__row
			margin-top: 180px
	&__col
		margin: 20px
		&.--content
			flex-basis: 50%
		&.--pic
			width: 100%
			max-width: 390px
			border-radius: 10px
	&__name
		color: $brand
	&__desc
		margin: 60px 0px 0px
		li
			& + li
				margin: 30px 0px 0px
					
	// <= 768px
	+media-table-s()
		&__title
			margin: 0px 0px 40px
		&__row
			flex-direction: column
			& + .cases__row
				margin-top: 60px
		&__col
			&.--pic
				order: -1
				border-radius: 0px
			&.--content
				flex-basis: 100%
				width: calc(100% - 40px)
		&__desc
			margin: 20px 0px 0px


// how
.how
	color: #fff
	position: relative
	margin: 0px 0px 100px
	background-color: $brand-dark	
	&:before
		content: ''
		
		right: -10px
		bottom: -100px
		left: -10px
		position: absolute
				
		height: 100px
		display: block
		background-color: $brand-dark
		border-radius: 0 0 50% 50% / 0 0 100% 100%;
	&__title
		text-align: center
		margin: 0px 0px 90px
	&__row
		display: flex
		flex-wrap: wrap
		margin: -30px
	&__col
		margin: 30px
		text-align: center
		flex-basis: calc((100% / 3) - 60px)
		span
			display: block
			font-size: 48px
			font-weight: bold
			margin: 0px 0px 50px
		p
			display: block
			margin: auto
			max-width: 200px
	
	// <= 768px
	+media-table-s()
		&__col
			flex-basis: calc(100% - 60px)
			p
				max-width: none


// inc
.inc
	&__title
		color: $brand
		text-align: center
	&__content
		margin: 80px 0px 0px
	&__row
		display: flex
		align-items: center
		margin: 0px -25px -25px
	&__col
		margin: 25px
		flex-basis: 50%
		&.--pic
			width: 100%
			max-width: 645px
		
		.title
			color: $brand
		ul
			margin: 10px 0px
			li
				position: relative
				padding: 0px 0px 0px 20px
				&:before
					content: ''
					top: 7px
					left: 0px
					position: absolute
					width: 5px
					height: 5px
					display: block
					border-radius: 100px
					background-color: $black
				& + li
					margin: 10px 0px 0px
	&__desc
		margin: 40px 0px 0px
	
	// <= 768px
	+media-table-s()
		&__row
			flex-direction: column
		&__col
			width: calc(100% - 50px)
			&.--pic
				order: -1


// profit
.profit
	background: url('../img/illustration-5.svg') no-repeat right 130%
	background-size: auto 380px
	&__container
		position: relative
		&:after
			content: ''

			top: 180px
			right: 0px
			position: absolute

			opacity: .5
			height 175px
			width: 200px
			display: block
			background: url('../img/quadrocopter.png') no-repeat
			background-size: contain
	&__title
		color: $brand
		text-align: center
	&__main
		font-size: 36px
	&__list
		margin: 50px 0px
		list-style-type: none
		counter-reset: aboutCounter
		li
			padding-left: 30px
			position: relative
			&:before
				content: counter(aboutCounter)
				counter-increment: aboutCounter
				font-size: 36px

				top: 0px
				left: 0px
				position: absolute
			& + li
				margin: 50px 0px 0px
	

	// <= 980px
	+media-table-m()
		&__main
			font-size: 18px
		&__list
			li
				&:before
					font-size: 18px

