/*@import url("../fonts/TT Norms Pro/stylesheet.css");*/
*,
*:before,
*:after,
*:focus {
  /* padding: 0; */
  margin: 0;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}


*:focus {
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #3987CF;
}
a:not([class]):hover {
    color: #95aaff;
}
button[disabled] {
    opacity: .2;
}
li {
  list-style: none;
}

body {
  font-family: "TT Norms Pro", sans-serif;
  position: relative;
}

body.lock {
  overflow: hidden;
}

/*-----header----*/
.header {
  padding-bottom: 220px;
  background: url("../img/phone02.jpg") 70% center no-repeat;
  background-size: cover;
  position: relative;
    margin-bottom: 80px;
}

.header2 {
  padding-bottom: 220px;
  background: url("../img/soft.png") 70% center no-repeat;
  background-size: cover;
  position: relative;
    margin-bottom: 80px;
}

.header3 {
  padding-bottom: 220px;
  background: url("../img/calc.png") 70% center no-repeat;
  background-size: cover;
  position: relative;
    margin-bottom: 80px;
}

@media (max-width: 575px) {
  .header:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    z-index: 0;
  }
}
.wrap {
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 20px;
}

.wrap_header {
  position: relative;
  z-index: 1;
}

.header_top {
  padding-top: 33px;
  padding-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 575px) {
  .header_top {
    padding-bottom: 150px;
  }
}
.header_top_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_logo {
  margin-right: 80px;
  width: 204px;
  height: 38px;
  position: relative;
  z-index: 1;
}
.header_logo img {
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .header_logo {
    width: 142px;
    height: 25px;
  }
}
@media (max-width: 1200px) {
  .header_logo {
    margin-right: 20px;
  }
}
.burger {
  display: none;
  width: 30px;
  height: 18px;
  position: relative;
  cursor: pointer;
}
.burger:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background-color: #3987CF;
  top: 0;
  right: 0;
}
.burger:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background-color: #3987CF;
  bottom: 0;
  right: 0;
}
.burger span {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background-color: #3987CF;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 991px) {
  .burger {
    display: block;
    position: absolute;
    z-index: 1;
    top: 35px;
    right: 20px;
  }
}
@media (max-width: 991px) {
  .nav {
    opacity: 0;
    position: absolute;
    display: none;
  }
}
@media (max-width: 991px) {
  .nav.active {
    background-color: #fff;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    top: 0;
    left: 0;
  }
}
.header_nav_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .header_nav_list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 150px;
    margin-bottom: 120px;
  }
}
.header_nav_list_item {
  margin-right: 40px;
  height: 50px;
  display: flex;
  align-items: start;
  position: relative;
  top: 16px;
}
.header_nav_list_item:last-child {
  margin: 0;
}

@media (max-width: 1200px) {
  .header_nav_list_item {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .header_nav_list_item {
    margin: 0 0 33px;
  }
}
.header_nav_link {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #1F1F1F;
}
.header_nav_link:hover {
  border-bottom: 3px solid #3987CF;
}

.header_top_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 991px) {
  .header_top_right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.header_tel {
  margin-right: 25px;
}

@media (max-width: 991px) {
  .header_tel {
    padding-bottom: 25px;
  }
}
.header_tel_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #1F1F1F;
}

@media (max-width: 991px) {
  .header_tel_text {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    color: #3987CF;
  }
}
.btn_blue {
  width: 230px;
  height: 50px;
  border-radius: 10px;
  background: -o-linear-gradient(179deg, #3987CF 0%, #025383 96.23%), #3987CF;
  background: linear-gradient(179deg, #3987CF 0%, #025383 96.23%), #3987CF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_signup_btn {
  margin-right: 25px;
}

@media (max-width: 991px) {
  .header_signup_btn {
    margin: 0 0 25px;
    width: 90%;
  }
}
.btn_blue_link {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.header_signup_link {
  padding: 15px 45px;
}

@media (max-width: 991px) {
  .header_signin {
    margin: 0 0 35px;
  }
}
.header_signin_link {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #1F1F1F;
}

.header_content {
  width: 50%;
  padding-bottom: 55px;
}

@media (max-width: 575px) {
  .header_content {
    width: 100%;
  }
}
.header_content_title {
  padding-bottom: 50px;
}

@media (max-width: 575px) {
  .header_content_title {
    padding-bottom: 20px;
  }
}
.header_content_header {
  font-style: normal;
  font-weight: 800;
  line-height: 68px;
  color: #3987CF;
  font-size: calc(22px + 29 * ((100vw - 320px) / 1600));
  line-height: calc(38px + 30 * ((100vw - 320px) / 1600));
}

@media (max-width: 575px) {
  .header_content_header {
    text-align: center;
  }
}
.header_content_subtitle {
  padding-bottom: 50px;
}

.header_content_text {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  font-size: calc(17px + 7 * ((100vw - 320px) / 1600));
  line-height: calc(22px + 18 * ((100vw - 320px) / 1600));
  color: #888;
}

@media (max-width: 575px) {
  .header_content_text {
    text-align: center;
  }
}
@media (max-width: 575px) {
  .header_content_btn {
    width: 100%;
  }
}
.header_bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_bottom_pic {
  width: 38px;
  height: 62px;
}
.header_bottom_pic img {
  width: 100%;
  height: 100%;
}

.header_bottom_link {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #3987CF;
}

/*-----header----*/
/*-----start slider---*/
.mpsklad_slider {
  padding-bottom: 100px;
}

.slider_title {
  padding-bottom: 100px;
}

.slider_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #3987CF;
  position: relative;
  padding-left: 80px;
}
.slider_header:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #3987CF;
}
.slider_card img {
  max-width: 100%;
}
.slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  padding-top: 10px;
}

.slick-list {
  overflow: hidden;
}

.slider .slick-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.slider .slick-dots li {
  width: 30px;
  height: 2px;
  border: 1px solid #3987CF;
  margin-right: 5px;
}
.slider .slick-dots li.slick-active {
  height: 6px;
}
.slider .slick-dots li button {
  font-size: 0;
}

.slider {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding-bottom: 45px;
}

.slider_card {
  margin: 0 10px 10px;
  width: 305px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  padding: 50px 10px 20px;
  margin-bottom: 5px;
}

.slider_card_pic {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}
.slider_card_pic img {
  width: 100px;
  height: 100%;
}

.slider_card_title {
  padding-bottom: 20px;
  height: 65px;
}

@media (max-width: 1200px) {
  .slider_card_title {
    height: 88px;
  }
}
.slider_card_header {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  color: #3987CF;
}

.slider_card_text {
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

/*-----start slider---*/
/*------start xp----*/
.xp {
  background: url("../img/l_xp.png") no-repeat calc(50vw - 900px) bottom, url("../img/r_xp.png") no-repeat calc(50vw + 450px) bottom, #3987CF;
}

@media (max-width: 1200px) {
  .xp {
    background-image: none;
  }
}
.xp_wrap {
  padding-top: 50px;
  padding-bottom: 50px;
}

.xp_title {
  position: relative;
  padding-left: 80px;
  margin-bottom: 50px;
}
.xp_title:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #fff;
  left: 0;
  top: 20px;
}

.xp_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #fff;
}

@media (max-width: 767px) {
  .xp_header {
    font-size: 28px;
  }
}
.xp_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .xp_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.xp_card {
  width: 350px;
}
.xp_card:nth-child(2) .xp_card_pic {
  position: relative;
}
.xp_card:nth-child(2) .xp_card_pic:before {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 205px;
  height: 5px;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.xp_card:nth-child(2) .xp_card_pic:after {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 205px;
  height: 5px;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .xp_card {
    width: 320px;
  }
  .xp_card:nth-child(2) .xp_card_pic:after, .xp_card:nth-child(2) .xp_card_pic:before {
    width: 175px;
  }
}
@media (max-width: 991px) {
  .xp_card {
    width: 241px;
  }
  .xp_card:nth-child(2) .xp_card_pic:after, .xp_card:nth-child(2) .xp_card_pic:before {
    width: 100px;
  }
}
@media (max-width: 767px) {
  .xp_card {
    width: 241px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin-bottom: 50px;
  }
  .xp_card:nth-child(2) .xp_card_pic:before {
    width: 5px;
    height: 55px;
    -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: 100%;
    left: 50%;
  }
  .xp_card:nth-child(2) .xp_card_pic:after {
    width: 5px;
    height: 55px;
    -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: auto;
    bottom: 100%;
    right: auto;
    left: 50%;
  }
}
@media (max-width: 575px) {
  .xp_card:nth-child(2) .xp_card_pic:before {
    width: 5px;
    height: 75px;
    -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: 100%;
    left: 50%;
  }
  .xp_card:nth-child(2) .xp_card_pic:after {
    width: 5px;
    height: 75px;
    -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: auto;
    bottom: 100%;
    right: auto;
    left: 50%;
  }
}
@media (max-width: 575px) {
  .xp_card {
    height: 115px;
  }
}
.xp_card_pic {
  width: 150px;
  height: 150px;
  border: 5px solid #FFFFFF;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  margin-bottom: 22px;
}

@media (max-width: 767px) {
  .xp_card_pic {
    margin: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 25px;
  }
}
@media (max-width: 575px) {
  .xp_card_pic {
    width: 100px;
    height: 100px;
  }
}
.xp_card_pic_wrap {
  width: 85px;
  height: 85px;
}
.xp_card_pic_wrap img {
  width: 100%;
  height: 100%;
}

@media (max-width: 575px) {
  .xp_card_pic_wrap {
    width: 57px;
    height: 57px;
  }
}
.xp_card_text {
  font-style: normal;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: calc(15px + 3 * ((100vw - 320px) / 1600));
  line-height: calc(19px + 3 * ((100vw - 320px) / 1600));
}

@media (max-width: 767px) {
  .xp_card_text {
    text-align: left;
  }
}
.xp_btn {
  margin: 0 auto;
}

.btn_w {
  width: 250px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.xp_btn_link {
  padding: 15px 50px;
}

.btn_w_link {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #3987CF;
}

/*------end xp----*/
/*-----start works----*/
.works_wrap {
  padding-bottom: 150px;
}

.works_title {
  padding-top: 135px;
  padding-bottom: 105px;
}

.works_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #3987CF;
  padding-left: 80px;
  position: relative;
}
.works_header:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #3987CF;
  top: 20px;
  left: 0;
}

@media (max-width: 767px) {
  .works_header {
    font-size: 28px;
  }
}
.works_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

@media (max-width: 757px) {
  .works_content {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
}
.works_card {
  width: 25%;
  position: relative;
}
.works_card:after {
  content: "";
  width: 2px;
  height: 60px;
  position: absolute;
  background-color: #3987CF;
  right: 0;
  top: 50px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.works_card:last-child:after {
  content: none;
}

@media (max-width: 757px) {
  .works_card {
    width: 50%;
    padding: 27px 0 33px;
  }
  .works_card:after {
    height: 100%;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    top: 0;
  }
  .works_card:nth-child(1):before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #3987CF;
  }
  .works_card:nth-child(2):before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #3987CF;
  }
  .works_card:nth-child(2):after {
    content: none;
  }
}
.works_card_pic {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}
.works_card_pic img {
  width: 100%;
  height: 100%;
}

@media (max-width: 757px) {
  .works_card_pic {
    width: 80px;
    height: 80px;
  }
}
.works_card_desc {
  padding: 0 50px;
}

@media (max-width: 757px) {
  .works_card_desc {
    padding: 0 10px;
  }
}
.works_card_text {
  text-align: center;
  font-style: normal;
  font-weight: 800;
  color: #3987CF;
  font-size: calc(15px + 3 * ((100vw - 320px) / 1600));
  line-height: calc(19px + 3 * ((100vw - 320px) / 1600));
}

/*-----end works----*/
/*-----start tarifs---*/
.tarifs {
  padding-bottom: 100px;
}

.tarifs_title {
  padding-bottom: 165px;
}

.tarifs_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #3987CF;
  position: relative;
  padding-left: 80px;
}
.tarifs_header:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #3987CF;
  top: 20px;
  left: 0;
}

@media (max-width: 767px) {
  .tarifs_header {
    font-size: 28px;
  }
}
.tarifs_content {
  border: 2px solid #3987CF;
  border-radius: 10px;
  padding-top: 95px;
  padding-bottom: 50px;
  position: relative;
}

@media (max-width: 767px) {
  .tarifs_content {
    padding-bottom: 0;
  }
}
.tarifs_from {
  padding: 15px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, -57%);
      -ms-transform: translate(-50%, -57%);
          transform: translate(-50%, -57%);
}

.tarifs_from_price {
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px;
  color: #3987CF;
  font-size: calc(48px + 16 * ((100vw - 320px) / 1600));
  line-height: calc(38px + 20 * ((100vw - 320px) / 1600));
  white-space: nowrap;
}

.tarifs_from_unit {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  color: #c4c4c4;
  text-align: center;
  font-size: calc(24px + 12 * ((100vw - 320px) / 1600));
  line-height: calc(24px + 8 * ((100vw - 320px) / 1600));
}

.tarifs_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

@media (max-width: 991px) {
  .tarifs_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (max-width: 1200px) {
  .tarifs_row {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
.tarifs_card {
  width: 305px;
  margin-right: 100px;
  position: relative;
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
  padding: 25px 0;
}
.tarifs_card:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 2px;
  background-color: #3987CF;
  right: -70px;
  top: 50%;
}
.tarifs_card:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 40px;
  background-color: #3987CF;
  right: -50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.tarifs_card:first-child .tarifs_card_desc p {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-top: -10px;
}
.tarifs_card:last-child {
  margin-right: 0;
}
.tarifs_card:last-child:before {
  content: none;
}
.tarifs_card:last-child:after {
  content: none;
}

@media (max-width: 1200px) {
  .tarifs_card {
    margin-bottom: 90px;
  }
}
@media (max-width: 991px) {
  .tarifs_card {
    margin-right: 0;
  }
  .tarifs_card:before {
    top: auto;
    bottom: -35px;
    right: 50%;
    -webkit-transform: translatex(50%);
        -ms-transform: translatex(50%);
            transform: translatex(50%);
  }
  .tarifs_card:after {
    top: auto;
    bottom: -35px;
    right: 50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
  }
}
@media (max-width: 575px) {
  .tarifs_card:last-child {
    margin-bottom: 50px;
  }
}
.tarifs_card_pic {
  width: 100px;
  height: 100px;
  margin: 0 auto 16px;
}
.tarifs_card_pic img {
  width: 100%;
  height: 100%;
}

.tarifs_card_desc {
  width: 254px;
  height: 58px;
  margin: 0 auto 15px;
  background: url("../img/bg_tarifs.png") center no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 5px 20px 0;
}

.tarifs_card_desc_text {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 17px;
  color: #fff;
}

.tarifs_card_price {
  height: 58px;
}

.tarifs_card_price_text {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 47px;
  color: #3987CF;
  text-align: center;
}

.tarifs_card_unit_text {
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 31px;
  color: #c4c4c4;
  text-align: center;
}

/*-----end tarifs---*/
/*-----start zayavka---*/
.zayavka {
  background-color: #3987CF;
}

.zayavka_wrap {
  padding-top: 50px;
  padding-bottom: 55px;
}

.zayavka_title {
  margin-bottom: 50px;
}

.zayavka_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #fff;
  padding-left: 80px;
  position: relative;
  font-size: calc(27px + 9 * ((100vw - 320px) / 1600));
  line-height: calc(32px + 11 * ((100vw - 320px) / 1600));
}
.zayavka_header:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #fff;
  top: 20px;
  left: 0;
}

@media (max-width: 767px) {
  .zayavka_header {
    font-size: 28px;
  }
}
#zayavka {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#zayavka input {
  width: 460px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 14px 20px;
  margin-right: 20px;
  color: #3987CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
#zayavka input:last-child {
  margin: 0;
}
#zayavka ::-webkit-input-placeholder {
  color: #3987CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
#zayavka ::-moz-placeholder {
  color: #3987CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
#zayavka :-ms-input-placeholder {
  color: #3987CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
#zayavka ::-ms-input-placeholder {
  color: #3987CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
#zayavka ::placeholder {
  color: #3987CF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@media (max-width: 1480px) {
  #zayavka input {
    width: 30%;
  }
}
@media (max-width: 991px) {
  #zayavka {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #zayavka input {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}
@media (max-width: 575px) {
  #zayavka {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #zayavka input {
    width: 100%;
  }
}
.zayavka_btn {
  margin: 50px auto 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #3987CF;
}

@media (max-width: 575px) {
  .zayavka_btn {
    width: 100%;
  }
}
/*-----end zayavka---*/
/*-----start warehouse---*/
.warehouse_wrap {
  padding-top: 100px;
  padding-bottom: 100px;
}

.warehouse_title {
  padding-bottom: 55px;
}

.warehouse_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #3987CF;
  padding-left: 80px;
  position: relative;
}
.warehouse_header:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #3987CF;
  top: 20px;
  left: 0;
}

@media (max-width: 767px) {
  .warehouse_header {
    font-size: 28px;
  }
}
.warehouse_row .slick-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.warehouse_row .slick-dots li {
  width: 30px;
  height: 2px;
  border: 1px solid #3987CF;
  margin-right: 5px;
}
.warehouse_row .slick-dots li.slick-active {
  height: 6px;
}
.warehouse_row .slick-dots li button {
  font-size: 0;
}

.warehouse_row {
  width: 100%;
  margin: 0 -22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.warehouse_left {
  padding: 0 22px;
}

.warehouse_left_row {
  margin: 0 -22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 34px;
}
.warehouse_left_row:last-child {
  margin-bottom: 0;
}

.warehouse_sq {
  padding: 0 22px;
}

.warehouse_sq_wrap {
  background-color: #3987CF;
  width: 343px;
  height: 343px;
}

.warehouse_hrect {
  padding: 0 22px;
  width: 63%;
}

.warehouse_hrect_wrap {
  background-color: #3987CF;
  width: 587px;
  height: 343px;
}

.warehouse_right {
  padding: 0 22px;
}

.warehouse_right_vrect {
  width: 422px;
  height: 720px;
  background-color: #3987CF;
}

/*-----end warehouse---*/
/*-----start clients---*/
.clients_wrap {
  padding-bottom: 110px;
}

.clients_title {
  padding-bottom: 60px;
}

@media (max-width: 575px) {
  .clients_title {
    padding-bottom: 30px;
  }
}
.clients_header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 43px;
  color: #3987CF;
  padding-left: 80px;
  position: relative;
}
.clients_header:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #3987CF;
  top: 20px;
  left: 0;
}

@media (max-width: 767px) {
  .clients_header {
    font-size: 28px;
  }
}
.clients_slider .slick-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.clients_slider .slick-dots li {
  width: 30px;
  height: 2px;
  border: 1px solid #3987CF;
  margin-right: 5px;
}
.clients_slider .slick-dots li.slick-active {
  height: 6px;
}
.clients_slider .slick-dots li button {
  font-size: 0;
}

.clients_slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  min-width: 0;
}

.clients_slider_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*-----end clients---*/
/*-----start map---*/
.map {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 5px;
}

.map_wrap iframe {
  height: 450px;
}

@media (max-width: 991px) {
  .map_wrap iframe {
    height: 800px;
  }
}
.map_contacts {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transform: skewX(35deg) translateX(-200px);
      -ms-transform: skewX(35deg) translateX(-200px);
          transform: skewX(35deg) translateX(-200px);
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-right: 170px;
}

@media (max-width: 1480px) {
  .map_contacts {
    width: 62%;
  }
}
@media (max-width: 1200px) {
  .map_contacts {
    -webkit-transform: skewX(0);
        -ms-transform: skewX(0);
            transform: skewX(0);
    padding-right: 20px;
    width: 35%;
  }
}
@media (max-width: 991px) {
  .map_contacts {
    height: 55%;
    width: 100%;
    -webkit-transform: skewY(-15deg);
        -ms-transform: skewY(-15deg);
            transform: skewY(-15deg);
    -webkit-transform-origin: left center;
        -ms-transform-origin: left center;
            transform-origin: left center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 50px;
  }
}
.map_contacts_wraping {
  -webkit-transform: skewX(-35deg);
      -ms-transform: skewX(-35deg);
          transform: skewX(-35deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

@media (max-width: 1200px) {
  .map_contacts_wraping {
    -webkit-transform: skewX(0deg);
        -ms-transform: skewX(0deg);
            transform: skewX(0deg);
  }
}
@media (max-width: 991px) {
  .map_contacts_wraping {
    -webkit-transform: skewY(15deg);
        -ms-transform: skewY(15deg);
            transform: skewY(15deg);
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
@media (max-width: 991px) {
  .map_title {
    padding-bottom: 30px;
  }
}
.map_header {
  font-style: normal;
  font-weight: 800;
  color: #3987CF;
  padding-left: 80px;
  position: relative;
  font-size: calc(27px + 9 * ((100vw - 320px) / 1600));
  line-height: calc(32px + 11 * ((100vw - 320px) / 1600));
}
.map_header:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #3987CF;
  top: 20px;
  left: 0;
}

@media (max-width: 767px) {
  .map_header {
    font-size: 28px;
  }
}
.map_adr {
  position: relative;
  padding-left: 35px;
  margin-left: 80px;
}
.map_adr:before {
  content: url("../img/point.png");
  top: 60%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  position: absolute;
}

@media (max-width: 991px) {
  .map_adr {
    margin: 0 0 20px;
  }
}
.map_adr_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #3987CF;
}

.map_tel {
  position: relative;
  padding-left: 35px;
  margin-left: 80px;
}
.map_tel:before {
  content: url("../img/tel.png");
  top: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 991px) {
  .map_tel {
    margin: 0 0 33px;
  }
}
.map_tel_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #3987CF;
}

.map_email {
  position: relative;
  padding-left: 40px;
  margin-left: 80px;
}
.map_email:before {
  content: url("../img/email.png");
  top: 70%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  position: absolute;
}

@media (max-width: 991px) {
  .map_email {
    margin: 0 0 45px;
  }
}
.map_email_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #3987CF;
}

.map_btn {
  margin-left: 80px;
}

@media (max-width: 991px) {
  .map_btn {
    margin: 0;
  }
}
.map_btn_link {
  padding: 15px 65px;
}

/*-----end map---*/
/*-----start footer---*/
.footer {
  padding-top: 50px;
  padding-bottom: 25px;
  background: white;
  z-index: 22;
  position: relative;
}

@media (max-width: 991px) {
  .footer {
    display: none;
  }
}
.footer_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer_logo {
  margin-bottom: 10px;
  width: 204px;
  height: 38px;
}
.footer_logo img {
  width: 100%;
  height: 100%;
}

.footer_conf {
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .footer_conf {
    text-align: center;
    margin: 0 0 20px;
  }
}
.footer_conf_link {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-decoration: underline;
  color: #c0c0c0;
}

@media (max-width: 991px) {
  .footer_copy {
    text-align: center;
  }
}
.footer_copy_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #1F1F1F;
}
.clients_slider_custom {
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

.client_slider_item_left {
	width: 250px;
	height: 200px;
	padding: 25px;
	margin: 25px;
	border: 1px solid #cfcfcf;
	margin-top: 0;
}

.client_slider_item_right p:nth-child(1) {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 15px;
}

.clients_slider_item {
	align-items: center;
}

.client_slider_item_left a {
	width: 100%;
	height: 100%;
	display: block;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.client_slider_item_left a img {
	width: 100%;
	height: auto;
}

.clients_slider_custom .clients_slider_item:nth-child(2) img {
	width: auto;
	height: 100%;
}
.header_nav_list li ul {
    display: none;
    position: absolute;
    background: white;
    padding: 5px 0;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 11%);
    margin-top: 34px;
}

.header_nav_list > li:after {
    display: none;
    height: 20px;
    content: '';
    width: 100%;
    line-height: normal;
}
.header_nav_list li:hover ul:before {
    content: '';
    display: block;
}
.header_nav_list li:hover ul {
    display: block;
}
.header_nav_list li ul li {
    padding: 10px 25px;
    border-bottom: 1px solid rgb(210 210 212 / 63%);
    white-space: nowrap;
}
.header_nav_list li.header_nav_list_item_arrow:after {
    content: '▼';
    font-size: 9px;
    display: block;
    color: #414141;
    position: relative;
    top: 6px;
    left: 5px;
}
.header_nav_list li.header_nav_list_item_arrow:hover:after {
    transform: rotate(180deg);
    top: -3px;
}
.header_nav_list li ul li:last-child {
    border-color: white;
}
@media (max-width: 775px) {
    .clients_slider_item {    
        align-items: unset;
        flex-direction: column;
        margin-bottom: 35px;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 30px;
        width: 100%;
    }
        
    .header_nav_list li:hover ul {
        position: absolute;
        left: 0;
        margin-left: -86px;
        z-index: 22;
    }
}
/*-----end footer---*/