
body.blog .header {
    background: transparent;
    padding-bottom: 0px;
}
body.blog .header.new-footer {
    padding-top: 50px;
    background: white;
    margin-bottom: 0;
}

body.blog .header.new-footer .wrap .wrap {
    background-image: unset!important;
}
body.blog .xp {
    background-image: unset!important;
    margin-bottom: 80px;
}

body.blog .blog-content a {
    color: #0158FF;
    border-bottom: 1px solid #0158FF;
    transition: all .3s;
}
body.blog .blog-content a:hover {
    color: #025383;
    border-bottom: 1px solid transparent;
}
body.blog .blog-content hr {
    width: 60px;
    height: 2px;
    background: #404040;
    margin-bottom: 15px;
}
body.blog .blog-content h3,
body.blog .blog-content h2 {
    margin-bottom: 15px;
    color: #404040;
    margin-top: 15px;
}
body.blog .blog-content .img-right {
    float: right;
    margin-left: 40px;
}
body.blog .blog-content {
    padding-bottom: 0;
    font-size: 18px;
    line-height: 35px;
    overflow: hidden;
    position: relative;
}
body.blog .blog-content ul li {
    list-style: disc;
}
body.blog .blog-content ol li {
    list-style: decimal;
    
}
body.blog .blog-content ul,
body.blog .blog-content ol {
    margin: 25px 25px;
}
body.blog .blog-content .img {
    width: auto;
    margin: 25px 0;
    display: inline-block;
}
body.blog .blog-content .img p {
    text-align: right
}
body.blog .blog-content blockquote {
    color: #3987CF;
    margin: 33px 0;
    font-weight: bold;
}
body.blog .blog-content:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    margin-top: 53px;
    background: #E0E0E0;
}
body.blog .xp-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
}
body.blog .xp_title {
    display: flex;
    margin-bottom: 0;
}
body.blog .xp_title p {
    font-size: 144px;
    font-weight: bold;
    color: #619fd9;
    margin-right: 85px;
}
body.blog .xp_title h1 {
    font-size: 52px;
    line-height: 55px;
    display: flex;
    align-items: center;
}
body.blog .xp_title:before {
    display: none;
}
body.blog .header_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}
body.blog .header .wrap {
    background-image: url(../img/blog/main-image.svg);
    background-size: auto;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 47%;
}
body.blog .header.footer-links .wrap {
    background: transparent;
    border-bottom: 6px solid #E0E0E0;
}
body.blog .header.footer-links {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 55px;
    background: white;
    clear: both;
    position: relative;
}
body.blog .header.footer-links .header_content {
    width: 100%;
    padding-bottom: 150px;
}
body.blog .header.footer-links h4 {
    color: black;
    font-size: calc(15px + 29 * ((100vw - 320px) / 1600));
    line-height: calc(15px + 30 * ((100vw - 320px) / 1600));
    margin-bottom: 25px;
}
body.blog .header_content_header {
        font-size: calc(16px + 29 * ((100vw - 320px) / 1600));
        line-height: calc(30px + 30 * ((100vw - 320px) / 1600));
}
body.blog .header p.subtitle {
    color: gray;
    font-size: 24px;
    margin: 15px 0;
}
body.blog .header ol {
    font-size: 18px;
    margin-left: 25px;
}
body.blog .header ol li {
    list-style: decimal;
    margin: 15px 0;
    padding-left: 0px;
}
body.blog .header ol ::marker {

    text-align: left;
}
body.blog .header ol a {
    color: #0158FF;
    border-bottom: 1px solid #0158FF;
    transition: all .3s;
}
body.blog .header ol a:hover {
    color: #025383;
    border-bottom: 1px solid transparent;
}
.to_top {
    position: relative;
    z-index: 2147483647;
}
body.blog .map {
    display: none;
}
body.blog .blog-content-body {display: inline-block;position: relative;}
body.blog .blog-content-body .content-left .img {
    max-width: 100%;
}

body.blog .blog-content-body .content-left .img img {
    width: auto;
    max-width: 100%;
}
body.blog .blog-content-body .content-left {
    width: 75%;
    padding-right: 25px;
    float: left;
    box-sizing: border-box;
}
body.blog .blog-content-body .content-right {
    width: 25%;
    float: right;
    right: 0;
    top: 0;
}
body.blog .blog-content-body .content-right img {
    width: 100%;
    height: auto;
}
body.blog a.none {
    border-color: white;
}
@media (max-width: 991px) {
    body.blog .blog-content-body .content-left {
    width: 100%;
    padding-right: unset;
    float: unset;
}
    
    body.blog .header.new-footer {
        padding-top: 0;
    }
    body.blog .header.new-footer .wrap .wrap {
        background: transparent;
    }
    body.blog .header.new-footer > .wrap {
            padding-top: 260px;
    background-position: center 12px;
    }
    body.blog .blog-content-body .content-right {
        width: 100%!important;
        display: block!important;
        position: static!important;
        height: auto!important;
    }
    body.blog .header .wrap {
        background-position: center 100px;
        background-size: 80%;
    }
    body.blog .header .header_top {
        padding-bottom: 300px;
    }
    body.blog .header_bottom_pic {
        display: none;
    }
    body.blog .header {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    body.blog .xp_title {
        display: flex;
        margin-bottom: 0;
        flex-direction: column;
        align-items: center;
    }
    body.blog .xp_title h1 {
        font-size: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 35px;
    }
    body.blog .blog-content p {
        margin: 15px 0;
    }
    body.blog .blog-content img {
        max-width: 100%;
    }
    body.blog .blog-content {
        padding-bottom: 30px;
    }
    body.blog .blog-content:after {
        margin-top: 30px;
    }
    body.blog .header ol,
    body.blog .header ul,
    body.blog .blog-content {
        font-size: 14px;
        line-height: 24px;
    }
    body.blog .header ol li {
        margin: 5px 0;
    }
    body.blog .blog-content h2 {
        font-size: 24px;
        margin-bottom: 0;
    }
    body.blog .blog-content h3 {
        font-size: 18px;
    }
    body.blog .img-right {
        display: none;
    }
    body.blog .xp_title p {
        margin-right: 0;
        font-size: 96px;
    }
    body.blog .xp_title {
        padding-left: 0;
    }
    body.blog .header p.subtitle {
        font-size: 18px;
    }
    body.blog h1,
    body.blog h2,
    body.blog h3,
    body.blog h4 {
        text-align: left;
    }
    body.blog .map {
        display: block;
    }
    body.blog .header.footer-links .header_content {
        padding-bottom: 50px;
    }
    body.blog .header.footer-links .wrap {
        border-color: transparent;
    }
}