.blog-home
	padding: 20px
	&__title
		color: $brand
		font-size: 46px
	&__subtitle
		font-size: 26px 			
		font-weight: 500
		line-height: 165%
		color: $dark-gray
		margin: 0px 0px 20px
	
	&__filters
		display: flex
		flex-wrap: wrap		
		margin: 0px -10px
		&-item
			margin: 10px
			min-width: auto
			color: $brand
			border: 1px solid $brand
			&:hover
				color: #fff
				background-color: $brand	 					
			&.active
				color: #fff
				text-decoration: none
				background: $brand-gradient	 					
	
	&__articles
		margin: 50px 0px
		&-grid	
			display: flex
			flex-wrap: wrap			
			margin: -15px
	
	&__article
		margin: 15px
		min-height: 480px
		color: $black
		display: flex
		flex-direction: column
		border-radius: $radius
		width: calc((100% / 3) - 30px) // костыль, многие не могут работать в связке flex-basis + img
		flex-basis: calc((100% / 3) - 30px)
		&:hover 
			transform: translateY(-5px)
			.blog-home__article-title
				color: $brand
				text-decoration: underline
		&-pic
			width: 100%
			height: 230px
			display: block
			object-fit: cover
			background: $brand-gradient
		
		&-top
			overflow: hidden
			background-color: $brand
			border-radius: $radius $radius 0px 0px
		&-bottom
			display: flex
			flex: 1 0 auto
			flex-direction: column
			padding: 15px
			border-style: solid
			border-color: $light-gray
			border-width: 0px 2px 2px 2px
			border-radius: 0px 0px $radius $radius	
		
		&-main
			flex: 1 0 auto
		&-footer
			display: flex
			flex: 0 0 auto
			font-size: 14px
			margin: 20px 0px 0px
		&-property
			display: flex
			align-items: center
			color: $dark-gray
			& + .blog-home__article-property
				margin-left: 10px
				&:before
					content: ''
					width: 6px
					height: 6px
					display: block
					margin-right: 10px
					border-radius: 100px
					background-color: $dark-gray
		&-title
			font-size: 24px
			font-weight: bold
			line-height: normal
			margin: 0px 0px 20px
		&-text
			margin: 0px
			font-size: 16px
	
	&__pagination
		&-container
			display: flex
			align-items: center
			justify-content: center
		&-grid
			display: flex
			flex-wrap: wrap				
			margin: -5px
		&-item
			width: 48px
			height: 48px
			min-width: auto
			color: #fff
			padding: 5px
			margin: 5px
			background-color: $light-gray
			&:hover
				background-color: $brand									
			&.active
				background: $brand-gradient
	
	// <= 1280px
	+media-desktop-s()
		&__article
			width: calc((100% / 2) - 30px)
			flex-basis: calc((100% / 2) - 30px)
	
	// <= 768px	
	+media-table-s()
		&__title
			font-size: 36px
		&__subtitle
			font-size: 18px
		
		&__filters
			&-item
				flex-grow: 1

		&__article
			width: 100%
			flex-basis: 100%
	
	// <= 480px
	+media-phone-s()
		&__article
			&-title
				font-size: 18px
		
		&__pagination
			&-item
				width: 38px									
				height: 38px									