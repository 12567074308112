h1, h2, h3, h4, h5, h6, .title
	font-size: 36px
	font-weight: bold
	line-height: normal
	margin: 0px 0px 20px


// --- Адаптивная часть ---


// <= 980px
+media-table-m()
	h1, h2, h3, h4, h5, h6, .title
		font-size: 24px		