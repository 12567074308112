.f-request
    color: #fff
    background-color: $brand-dark
    input
        width: 100%        
    &__main
        display: flex
        margin: -20px
    &__item
        flex-grow: 1
        margin: 20px


// <= 980px
+media-table-m()
    .f-request
        &__main
            margin: -10px
        &__item
            margin: 10px
            &:last-child
                margin-top: 40px

// <= 768px
+media-table-s()
    .f-request
        &__main
            flex-direction: column