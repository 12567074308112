// ** desktop **

// <= 1440px
media-desktop-m(resolution = $lg-m)	
	@media screen and (max-width: resolution) 
		{block}

// <= 1280px
media-desktop-s(resolution = $lg-s)
	@media screen and (max-width: resolution) 
		{block}

// <= 1024px
media-desktop-xs(resolution = $lg-xs)	
	@media screen and (max-width: resolution) 
		{block}


// ** tablet **


// <= 980px
media-table-m(resolution = $medium-m)		
	@media screen and (max-width: resolution) 
		{block}	

// <= 768px
media-table-s(resolution = $medium-s)			
	@media screen and (max-width: resolution) 
		{block}


// ** phone **


// <= 650px
media-phone-m(resolution = $small-m)	
	@media screen and (max-width: resolution) 
		{block}	

// <= 480px
media-phone-s(resolution = $small-s)	
	@media screen and (max-width: resolution) 
		{block}	

// <= 380px
media-phone-xs(resolution = $small-xs)	
	@media screen and (max-width: resolution) 
		{block}		