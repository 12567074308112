button, .btn
	cursor: pointer
	font-size: 16px
	font-weight: bold
	font-family: $font-family
	text-decoration: none
	padding: 15px
	display: inline-flex
	align-items: center
	justify-content: center
	border-radius: $radius
	border: 2px solid transparent
	transition: all $transition
	svg
		transition: all $transition
	&:hover
		opacity: .8
	&:active
		transform: translateY(5px)	

.btn
	min-width: 230px	
	&--brand
		fill: #fff
		color: #fff
		background: $brand-gradient